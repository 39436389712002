export const queryWeapon = (weapons, queryType, value) => {
  if (!['name', 'tier', 'class'].includes(queryType)) {
    return null
  }
  
  var match = queryType == 'name' ? null : [];
  const accessor = queryType == 'class' ? 'wClass' : queryType;

  for (let x in weapons) {
    if (weapons[x][accessor] == value) {
      if (queryType == 'name') {
        match = weapons[x];
      } else {
        match.push(weapons[x]);
      }
    }
  }
  return match;
}

export const calculateDps = (wClass, magSize, baseAtk, fireRate, reloadTime, shellCapacity, magSizeMod, atkMod, reloadTimeMod, fireRateMod) => {
  // Validate required values exist
  if (magSize == 0) {
    return 0;
  }
  if (baseAtk == 0) {
    return 0;
  }
  if (fireRate == 0) {
    return 0;
  }
  if (reloadTime == 0) {
    return 0;
  }

  // Recalculate for shotgun type
  var atk = 0.00;
  if (wClass == 'SHOTGUN') {
    atk = baseAtk * shellCapacity;
  } else {
    atk = baseAtk;
  }

  // Apply modifiers
  atk = atk * atkMod;
  magSize = magSize * magSizeMod;
  reloadTime = reloadTime * reloadTimeMod;
  fireRate = fireRate * fireRateMod;

  const dps = ((60.000 / ((1.000 / (fireRate / 60.000)) + (reloadTime/magSize))) * atk) / 60.000;
  return dps.toFixed(2);
}

export const calculateCritDps = (dps, critDamage, critDamageMod) => {
  if (dps == 0.00) {
    return 0.00;
  }

  if (critDamage !== NaN) {
  } else {
    return 0.00;
  }

  return dps * critDamage * critDamageMod;
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
