import Navbar from '../components/Navbar.jsx';
import SocialIcons from './SocialIcons.jsx';

function Header() {
  return (
    <header>
      <div className="row">
        <h1 id="site-title" className="col-xl-4"><a href="/">TheoryForge</a></h1>
        <Navbar />
        <SocialIcons />
      </div>
    </header>
  );
}

export default Header;