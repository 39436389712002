import '../assets/site.css';

import { useEffect } from "react";

import Warning from "../components/Warning";

function About() {
  useEffect(() => {
    document.title = "TheoryForge.io - About the Site"
  }, [])

  return (
    <div className="About">
      <section>
        <div>
          <Warning />
        </div>
      </section>
      <section className="col-xl-9">
        <h2>About TheoryForge</h2>

        <p>
        TheoryForge is run by schism, current Division franchise fan and future <a href="https://tfd.nexon.com">First Descendant</a> fan. She has
limited play time and thus wanted tools to help her as she delves into the world of Theorycrafting and
pursuing efficient strategies for the upcoming game, The First Descendant.
        </p>

        <h3>Contact</h3>
        <ul id="contact-list">
          <li>
            <a href="https://twitter.com/_schism15"><img height="32" src="twitter_social_icon.svg" /> @_schism15</a>
          </li>
          <li>
            <a href="https://www.youtube.com/@schism15"><img height="32" src="youtube_social_icon.png" /> @schism15</a>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default About;
