import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = () => {
  return (
    <div className="page container-fluid col-xl-9">
      <Header /> 
      <main className="row">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;