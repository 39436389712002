import SocialIcons from './SocialIcons.jsx';

function Footer() {
  return (
    <footer>
      <div className="row">
        Copyright, 2023
        <SocialIcons />
      </div>
    </footer>
  );
}

export default Footer;