import '../assets/site.css';

import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { queryWeapon, calculateDps, calculateCritDps } from "./utils";

const GET_WEAPON_NAMES = gql`
query {
  allWeapons {
    edges {
      node {
        name
        wClass
        tier
        magSize
        fireRate
        shellCapacity
        critDamage
        imageUrl
      }
    }
  }
}
` 

function ResultsTable(props) {
  return (
    <div>
      <img src={props.imageUrl} key={`${props.imageUrl}?${new Date()}`} width="500" alt="Weapon image not found." className="img-responsive" />
      <table>
        <thead>
          <tr>
            <td>Stat</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>Name</td>
            <td>{props.name || ""}</td>
          </tr>
          <tr>
            <td>Magazine Size</td>
            <td>{props.magSize || 0}</td>
          </tr>
          <tr>
            <td>ATK</td>
            <td>{props.atk || 0}</td>
          </tr>
          <tr>
            <td>Fire Rate</td>
            <td>{props.fireRate || 0.00.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Reload Time</td>
            <td>{props.reloadTime || 0.00.toFixed(2)}</td>
          </tr>
          <tr>
            <td>DPS</td>
            <td>{props.dps || 0.00.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Critical Hit Damage</td>
            <td>{props.critDamage || 0.00.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Critical DPS</td>
            <td>{props.critDps || 0.00.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function WeaponCalculator(props) {
  /* Populate weapon dropdown */
  const [selectedTier, setSelectedTier] = useState(0);
  const changeTierSelectOptionHandler = (event) => {
    setSelectedTier(event.target.value);
  };

  /* Manage stats table state */
  /* Raw Stats */
  const [selectedWeapon, setSelectedWeapon] = useState({});
  const [atk, setAtk] = useState(0);
  const [reloadTime, setReloadTime] = useState(0.00);
  const [dps, setDps] = useState(0.00);
  const [critDps, setCritDps] = useState(0.00);

  /* Modifiers */
  const [critDamageMod, setCritDamageMod] = useState(1.00);
  const [magSizeMod, setMagSizeMod] = useState(1.00);
  const [atkMod, setAtkMod] = useState(1.00);
  const [fireRateMod, setFireRateMod] = useState(1.00);
  const [reloadTimeMod, setReloadTimeMod] = useState(1.00);

  const [imageUrl, setImageUrl] = useState("");

  const changeWeaponSelectOptionHandler = (event) => {
    setSelectedWeapon(queryWeapon(filteredWeapons, 'name', event.target.value));
    setImageUrl(selectedWeapon.imageUrl);
    setDps(
      calculateDps(
        selectedWeapon.wClass,
        selectedWeapon.magSize,
        atk,
        selectedWeapon.fireRate,
        reloadTime,
        selectedWeapon.shellCapacity,
        magSizeMod,
        atkMod,
        reloadTimeMod,
        fireRateMod
      )
    );
    setCritDps(
      calculateCritDps(
        dps,
        selectedWeapon.critDamage,
        critDamageMod
      )
    );
  };
  
  const updateAtkInputHandler = (event) => {
    setAtk(event.target.value);
    setDps(calculateDps(selectedWeapon.wClass, selectedWeapon.magSize, event.target.value, selectedWeapon.fireRate, reloadTime, selectedWeapon.shellCapacity, magSizeMod, atkMod, reloadTimeMod, fireRateMod));
    setCritDps(dps * selectedWeapon.critDamage * critDamageMod);
  };

  const updateReloadTimeInputHandler = (event) => {
    setReloadTime(event.target.value);
    const newDps = calculateDps(selectedWeapon.wClass, selectedWeapon.magSize, atk, selectedWeapon.fireRate, event.target.value, selectedWeapon.shellCapacity, magSizeMod, atkMod, reloadTimeMod, fireRateMod);
    setDps(newDps);
    setCritDps(
      calculateCritDps(
        newDps,
        selectedWeapon.critDamage,
        critDamageMod
      )
    );
  };

  const updateChdModifierInputHandler = (event) => {
    var newMod = null;
    if (event.target.value < 1.00) {
      newMod = 1.00;
      setCritDamageMod(newMod)
    } else {
      newMod = event.target.value;
      setCritDamageMod(newMod);
    }
    const newDps = calculateDps(selectedWeapon.wClass, selectedWeapon.magSize, atk, selectedWeapon.fireRate, reloadTime, selectedWeapon.shellCapacity, magSizeMod, atkMod, reloadTimeMod, fireRateMod);
    setDps(newDps);
    setCritDps(
      calculateCritDps(
        newDps,
        selectedWeapon.critDamage,
        newMod
      )
    );
  };

  const updateMagSizeModifierInputHandler = (event) => {
    var newMod = null;
    if (event.target.value < 1.00) {
      newMod = 1.00;
      setMagSizeMod(newMod);
    } else {
      newMod = event.target.value;
      setMagSizeMod(newMod);
    }
    const newDps = calculateDps(selectedWeapon.wClass, selectedWeapon.magSize, atk, selectedWeapon.fireRate, reloadTime, selectedWeapon.shellCapacity, newMod, atkMod, reloadTimeMod, fireRateMod);
    setDps(newDps);
    setCritDps(
      calculateCritDps(
        newDps,
        selectedWeapon.critDamage,
        critDamageMod
      )
    );
  };

  const updateAtkModifierInputHandler = (event) => {
    var newMod = null;
    if (event.target.value < 1.00){
      newMod = 1.00;
      setAtkMod(newMod);
    } else {
      newMod = event.target.value
      setAtkMod(newMod);
    }
    const newDps = calculateDps(selectedWeapon.wClass, selectedWeapon.magSize, atk, selectedWeapon.fireRate, reloadTime, selectedWeapon.shellCapacity, magSizeMod, newMod, reloadTimeMod, fireRateMod);
    setDps(newDps);
    setCritDps(
      calculateCritDps(
        newDps,
        selectedWeapon.critDamage,
        critDamageMod
      )
    );
  };

  const updateReloadTimeModifierInputHandler = (event) => {
    var newMod = null;
    if (event.target.value < 1.00){
      newMod = 1.00;
      setReloadTimeMod(newMod);
    } else {
      newMod = event.target.value;
      setReloadTimeMod(newMod);
    }
    const newDps = calculateDps(selectedWeapon.wClass, selectedWeapon.magSize, atk, selectedWeapon.fireRate, reloadTime, selectedWeapon.shellCapacity, magSizeMod, atkMod, newMod, fireRateMod);
    setDps(newDps);
    setCritDps(
      calculateCritDps(
        newDps,
        selectedWeapon.critDamage,
        critDamageMod
      )
    );
  };

  const updateFireRateModifierInputHandler = (event) => {
    var newMod = null;
    if (event.target.value < 1.00){
      newMod = 1.00;
      setFireRateMod(newMod);
    } else {
      newMod = event.target.value;
      setFireRateMod(newMod);
    }
    const newDps = calculateDps(selectedWeapon.wClass, selectedWeapon.magSize, atk, selectedWeapon.fireRate, reloadTime, selectedWeapon.shellCapacity, magSizeMod, atkMod, reloadTimeMod, newMod);
    setDps(newDps);
    setCritDps(
      calculateCritDps(
        newDps,
        selectedWeapon.critDamage,
        critDamageMod
      )
    );
  };


  /* Fetch Weapon Data from API */
  const { loading, error, data } = useQuery(GET_WEAPON_NAMES);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;


  const allWeapons = [];
  data.allWeapons.edges.forEach(element => allWeapons.push(
    {
      name: element.node.name,
      tier: element.node.tier,
      wClass: element.node.wClass,
      magSize: element.node.magSize,
      fireRate: element.node.fireRate,
      shellCapacity: element.node.shellCapacity,
      critDamage: element.node.critDamage,
      imageUrl: element.node.imageUrl,
    }
  ));

  const tierOptions = [
    {
      name: "1",
      value: 1
    },
    {
      name: "2",
      value: 2
    }
  ];

  let filteredWeapons = null;
  let weaponOptions = null;

  if (selectedTier == 1) {
    // filteredWeapons = tierFilter(allWeapons, 1);
    filteredWeapons = queryWeapon(allWeapons, 'tier', 1);
  } else if (selectedTier == 2) {
    // filteredWeapons = tierFilter(allWeapons, 2);
    filteredWeapons = queryWeapon(allWeapons, 'tier', 2);
  }

  if (filteredWeapons) {
    weaponOptions = filteredWeapons.map(({name, value}, index) => <option value={name} key={index}>{name}</option>);
  }

  return (
    <div id="weapon-calculator" className="col-xl-15">
      <div className="row">
        <div className="calculatorFieldset col-xl-6">
          <fieldset>
            {/* Select tier */}
            <label htmlFor="tier-select">Tier</label>
            <select name="tier" id="tier-select" className="form-select" onChange={changeTierSelectOptionHandler}>
              <option value="">---Select a Tier---</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
            <br />

            {/* Select weapon */}
            <label htmlFor="weapon-select">Weapon</label>
            <select name="weapon" id="weapon-select" className="form-select" onChange={changeWeaponSelectOptionHandler}>
              <option value="">---Select a Weapon---</option>
              {weaponOptions}
            </select>
            <br />

            {/* Basic Stat Inputs */}
            <label htmlFor="atk-input">ATK</label>
            <input type="text" id="atk-input" className="form-control" onBlur={updateAtkInputHandler} /><br />
            <label htmlFor="reload-speed-input">Reload Time</label>
            <input type="text" id="reload-speed-input" className="form-control" onBlur={updateReloadTimeInputHandler} /><br />
            
            {/* Bonus Stat Inputs */}
            <h3>Bonus Modifiers</h3>
            <p>Input any increase/decrease modifiers (e.g. 1.5 for 50% increase):</p>
            <label htmlFor="chd-modifier-input">Critical Hit Damage</label>
            <input type="text" id="chd-modifier-input" className="form-control" onBlur={updateChdModifierInputHandler} /><br />
            
            <label htmlFor="mag-size-modifier-input">Magazine Size</label>
            <input type="text" id="mag-size-modifier-input" className="form-control" onBlur={updateMagSizeModifierInputHandler} /><br />
            
            <label htmlFor="atk-modifier-input">ATK</label>
            <input type="text" id="atk-modifier-input" className="form-control" onBlur={updateAtkModifierInputHandler} /><br />
            
            <label htmlFor="reload-speed-modifier-input">Reload Speed</label>
            <input type="text" id="reload-speed-modifier-input" className="form-control" onBlur={updateReloadTimeModifierInputHandler} /><br />
            
            <label htmlFor="fire-rate-modifier-input">Fire Rate</label>
            <input type="text" id="fire-rate-modifier-input" className="form-control" onBlur={updateFireRateModifierInputHandler} /><br />
          </fieldset>
        </div>
        <div className="calculatorTable col-xl-5">
          <ResultsTable
            name={selectedWeapon.name}
            magSize={selectedWeapon.magSize * magSizeMod}
            fireRate={selectedWeapon.fireRate * fireRateMod}
            atk={atk * atkMod}
            reloadTime={reloadTime * reloadTimeMod}
            dps={dps}
            critDamage={selectedWeapon.critDamage * critDamageMod}
            critDps={critDps}
            imageUrl={selectedWeapon.imageUrl}
          />
      </div>
      </div>
    </div>
  );
}

export default WeaponCalculator