import '../assets/site.css';

function Warning() {
  return (
    <div className="Warning">
        <img id="warning-icon" src="warning.png" />
        The data used on this site is from the October 2022 open beta test. This data will change with subsequent tests and releases.
    </div>
  );
}

export default Warning;