import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavUnlisted = styled.ul`
  text-decoration-style: "none";
`;

const linkStyle = {
  color: "black",
  textDecoration: "none",
}

function Navbar() {
  return (
    <div className="col-xl-4">
    <NavUnlisted>
      <ul>
        <li className="navItem">
          <Link to="/" style={linkStyle}>Calculator</Link>
        </li>
        <li className="navItem">
          <Link to="/items" style={linkStyle}>Items</Link>
        </li>
        <li className="navItem">
          <Link to="/about" style={linkStyle}>About</Link>
        </li>
      </ul>
    </NavUnlisted>
    </div>
  );
}

export default Navbar;