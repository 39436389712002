import '../assets/site.css';

import { useEffect } from "react";

import Warning from "../components/Warning";
import WeaponItemList from '../components/WeaponItemList';

function Items() {
  useEffect(() => {
    document.title = "TheoryForge.io - Weapons Listing"
  }, [])

  return (
    <div className="Items">
      <section>
        <div>
          <Warning />
        </div>
      </section>
      <section>
        <WeaponItemList />
      </section>
    </div>
  );
}

export default Items;
