import '../assets/site.css';

import { useEffect } from "react";

import Warning from "../components/Warning";
import WeaponCalculator from '../components/WeaponCalculator';

function Calculator() {
  useEffect(() => {
    document.title = "TheoryForge.io - Weapon Calculator"
  }, [])

  return (
    <div className="Calculator">
      <section>
        <div>
          <Warning />
          <p>Welcome to TheoryForge’s Damage Calculator for <a href="https://www.nexon.com/main/en">Nexon</a>'s game, <a href="https://tfd.nexon.com">The First Descendant</a>! This calculator is a fan made project and it is a work in progress. The formula will be refined as the game moves closer to release. I created this calculator because I enjoy Looter Shooters and believe that The First Descendant is going to be an exciting new entry in the genre. I’m also learning about TheoryCrafting and want to build and share tools that I find helpful in building strategies for these games.</p>
          <p>To use the calculator select weapon class, tier and level. Then set ATK (base attack damage) and Reload Speed values in order to see the weapon’s DPS. Integration of Runes into this calculator is forthcoming.</p>
        </div>
      </section>
      <section>
        <WeaponCalculator slotNumber={1} />
      </section>
    </div>
  );
}

export default Calculator;
