import '../assets/site.css';

import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { capitalizeFirstLetter } from "./utils";

const GET_WEAPON_NAMES = gql`
query {
    allWeapons {
      edges {
        node {
          name
          wClass
          tier
          ammoType
          magSize
          fireRate
          runningSpeed
          critChance
          critDamage
          wave
          pierce
          explosionRangeAtkReductionRatio
          atkReductionStartDistance
          penetration
          burst
          shellCapacity
          imageUrl
        }
      }
    }
  }
` 

function DetailsTable(weapon) {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>Stat</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ammo Type</td>
            <td>{weapon.weapon.ammoType || "N/A"}</td>
          </tr>
          <tr>
            <td>Magazine Size</td>
            <td>{weapon.weapon.magSize || "N/A"}</td>
          </tr>
          <tr>
            <td>Fire Rate</td>
            <td>{weapon.weapon.fireRate || "N/A"}</td>
          </tr>
          <tr>
            <td>Running Speed</td>
            <td>{weapon.weapon.runningSpeed || "N/A"}</td>
          </tr>
          <tr>
            <td>Critical Hit Chance</td>
            <td>{weapon.weapon.critChance || "N/A"}</td>
          </tr>
          <tr>
            <td>Critical Hit Damage</td>
            <td>{weapon.weapon.critDamage || "N/A"}</td>
          </tr>
          <tr>
            <td>Wave</td>
            <td>{weapon.weapon.wave || "N/A"}</td>
          </tr>
          <tr>
            <td>Pierce</td>
            <td>{weapon.weapon.pierce || "N/A"}</td>
          </tr>
          <tr>
            <td>Explosion Range ATK Reduction Ratio</td>
            <td>{weapon.weapon.explosionRangeAtkReductionratio || "N/A"}</td>
          </tr>
          <tr>
            <td>ATK Reduction Start Distance</td>
            <td>{weapon.weapon.atkReductionStartDistance || "N/A"}</td>
          </tr>
          <tr>
            <td>Penetration</td>
            <td>{weapon.weapon.penetration || "N/A"}</td>
          </tr>
          <tr>
            <td>Burst</td>
            <td>{weapon.weapon.burst || "N/A"}</td>
          </tr>
          <tr>
            <td>Shell Capacity</td>
            <td>{weapon.weapon.shellCapacity || "N/A"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function WeaponCard(weapon) {
  const [detailsHidden, setDetailsHidden] = useState(false);
  
  return (
    <div className="item-card col-xl-10" onClick={(e) => {e.preventDefault(); setDetailsHidden(!detailsHidden);}}>
      <div className="item-card-cover row">
        <div className="item-image col-xl-4">
          <img src={weapon.weapon.imageUrl} key={`${weapon.weapon.imageUrl}?${new Date()}`} width="360" alt="Weapon image not found." className="img-responsive" />
        </div>
        <div className="item-caption col-xl-5">
          <p className="weapon-card-name">{weapon.weapon.name}</p>
          <p className="weapon-card-tier">{`Tier ${weapon.weapon.tier} | ${capitalizeFirstLetter(weapon.weapon.wClass).replace("_", " ")}`}</p>
        </div>
      </div>
      <div className="item-card-details">
        {detailsHidden && <DetailsTable weapon={weapon.weapon} />}
      </div>
    </div>
    )
}


function WeaponItemList(props) {
  /* Fetch Weapon Data from API */
  const { loading, error, data } = useQuery(GET_WEAPON_NAMES);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;


  const allWeapons = [];
  data.allWeapons.edges.forEach(element => allWeapons.push(
    {
      name: element.node.name,
      wClass: element.node.wClass,
      tier: element.node.tier,
      ammoType: element.node.ammoType,
      magSize: element.node.magSize,
      fireRate: element.node.fireRate,
      runningSpeed: element.node.runningSpeed,
      critChance: element.node.critChance,
      critDamage: element.node.critDamage,
      wave: element.node.wave,
      pierce: element.node.pierce,
      explosionRangeAtkReductionratio: element.node.explosionRangeAtkReductionratio,
      atkReductionStartDistance: element.node.atkReductionStartDistance,
      penetration: element.node.penetration,
      burst: element.node.burst,
      shellCapacity: element.node.shellCapacity,
      imageUrl: element.node.imageUrl,
    }
  ));

  return (
    <div id="weapon-calculator" className="col-xl-15">
      <p>Click the weapon entry for stat details.</p>
      {allWeapons.map(element => (<WeaponCard weapon={element} />))}

    </div>
  );
}

export default WeaponItemList